<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New User
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        > 
            <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          > 
          <b-form-group
              label="Name"
              label-for="name"
            >
               <b-form-input
                id="name"
                v-model="userData.user_name"
                placeholder="Enter name..." 
              ></b-form-input> 
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
 
            </b-form-group>
          </validation-provider> 
          <validation-provider
            #default="validationContext"
            name="Username"
            rules="required"
          > 
          <b-form-group
              label="Username"
              label-for="username"
            >
               <b-form-input
                id="username"
                v-model="userData.user_username"
                placeholder="Enter username..." 
              ></b-form-input> 
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
 
            </b-form-group>
          </validation-provider> 
          <b-form-group
              label="Email"
              label-for="email"
            >
               <b-form-input
                id="email"
                v-model="userData.user_email"
                placeholder="Enter email..." 
              ></b-form-input>  
 
            </b-form-group>
            <b-form-group
              label="Mobile"
              label-for="mobile"
            >
               <b-form-input
                id="mobile"
                v-model="userData.user_mobile"
                placeholder="Enter mobile..." 
              ></b-form-input>  
 
            </b-form-group>
 
          <b-form-group
              label="Role"
              label-for="role"
            >
            <b-form-select
                  v-model="userData.user_role"
                  :options="roleOptions" 
                />  
            
            </b-form-group> 
            <b-form-group
              label="Status"
              label-for="status"
            >
            
            <b-form-select
                  v-model="userData.user_status"
                  :options="statusOptions" 
                />  
            
            </b-form-group> 
 
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import Vue from 'vue'
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BFormTextarea,BFormDatepicker,BFormSelect
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BFormSelect,
    BFormDatepicker,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    vSelect, 
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email 
    }
  }, 
  mounted(){ 
   
  },

  setup(props, { emit }) { 
     

    const blankUserData = { }
    const roleOptions = ref(["User","Admin"]);
    const statusOptions = ref(["Active","Inactive"]);
    
    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetUserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      
      let userSessionData = JSON.parse(localStorage.getItem('userData'));
      userData.value.user_created_by = userSessionData.user_id;
      store.dispatch('app-user/addUser', userData.value)
        .then((response) => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        });
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetUserData)

    return {
      statusOptions,
      roleOptions,
      userData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
